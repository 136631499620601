<template>
  <div>
    <tabBar :cardIndex="2" />
    <div class="opusmall">
      <div class="titles">{{titles}}</div>
      <div class="imputs"> 
        <input type="text" v-if="activeindex == 2" @keyup.enter='sherchs' v-model="formparms.goodsName" placeholder="请输入您想查找的商品" class="inputs" />
        <input type="text" v-if="activeindex == 1" @keyup.enter='sherchs' v-model="parmsyingxiao.goodsName" placeholder="请输入您想查找的商品" class="inputs" />
        <div class="vielsiut" @click="sherchs">搜索</div>
      </div>
      <triangle
        :nums="-40"
        :viewbox="'0 0 1920 215'"
        :pathpoin="'1920 289.4 0 289.4 0 22 960 172.1 1920 22'"
        :compoin="'1920 0 1920 22 960 173.1 0 22 0 0 960 150.1'"
      ></triangle>
    </div>
    <div class="bgcenters">
      <div class="bgcenters_cen">
        <div class="texttitle" v-if="activeindex == 1">活动专区</div>
        <div class="fenleilistn" v-if="activeindex == 2">
          <div class="allgoods" @click="allgoodsins">全部积分商品</div>
          <div
            class="goodsli"
            ref="fenleilistn"
            @scroll.passive="getScroll($event)"
          >
            <div
              class="lis"
              v-for="(item, index) in tabbers"
              :key="index"
              @click="toactivecard(index, item.id)"
              ref="libox"
              :style="indexs == index ? 'color:#00A3E0;' : ''"
            >
              {{ item.name }}
            </div>
          </div>
        </div>
        <div class="lsitadposcen">
          <div
            class="goodeslist"
            v-for="(item, index) in goodsList"
            :key="index"
            @mouseenter="enter(index)"
            @mouseleave="leave(index)"
          >
            <img
              class="goodesimg"
              :src="item.thumbnail"
              />
            <div
              class="goodsbotom"
              :style="active == index ? 'border-bottom:1px solid #00A3E0' : ''"
            >
              <div class="titles">{{ item.goodsName }}</div>
              <div class="price">
                <span class="prices" v-if="activeindex == 1"
                  ><span class="rmbs">￥</span>{{ item.originalPrice }}</span
                >
                <span class="prices" v-if="activeindex == 2"
                  ><span class="rmbs"></span>{{ item.points }}积分
                  <span style="font-size: 16px; color: #999999"
                    >仅剩{{ item.activeStock }}份</span
                  ></span
                >
                <div
                  class="price_btns"
                  v-if="activeindex == 2 && active == index"
                  @click="gotos(1, item.id, item.goodsId)"
                >
                  立即兑换
                </div>
                <div
                  class="price_btns"
                  v-if="activeindex == 1 && active == index"
                  @click="gotos(2, item.goodsId)"
                >
                  立即购买
                </div>
                <div
                  class="price_btns price_btnsTwo"
                  v-if="activeindex == 1 && active != index"
                >
                  满{{item.coupon.consumeThreshold}}-{{item.coupon.price}}
                </div>
              </div>
            </div>
          </div>
        </div>
        <!-- <div class="btnsad">了解更多商品</div> -->
      </div>
    </div>
    <footers></footers>
  </div>
</template>

<script>
import tabBar from "@/components/tabBar.vue";
import triangle from "@/components/triangle.vue";
import footers from "@/components/footer.vue";
import {
  getGoodspointsGoodscategory,
  getGoodspointsGoods,
  getCouponGoodsList,
} from "../request/homeApi";
export default {
  data() {
    return {
      isAchiveBottom: false, //滚动条是否到底部标志
      active: null,
      activeindex: "",
      indexs: 60,
      tabbers: [],
      goodsList: [],
      parms: {
        pageNumber: 1,
        pageSize: 50,
      },
      formparms: {
        pageNumber: 1,
        pageSize: 12,
        pointsGoodsCategoryId: "",
        goodsName: "",
      },
      parmsyingxiao: {
        pageNumber: 1,
        pageSize: 12,
        goodsName: "",
      },
			total:'',
			totals:'',
			titles:''
    };
  },
  components: {
    tabBar,
    triangle,
    footers,
  },
  created() {
    //使用window.onscroll = function(){}this指向出现问题
    //故应该使用箭头函数，因为箭头函数无this，会从上一级找，故会找到vue实例的this
    window.onscroll = () => {
      //变量scrollTop是滚动条滚动时，距离顶部的距离
      var scrollTop =
        document.documentElement.scrollTop || document.body.scrollTop;
      //变量windowHeight是可视区的高度
      var windowHeight =
        document.documentElement.clientHeight || document.body.clientHeight;
      //变量scrollHeight是滚动条的总高度
      var scrollHeight =
        document.documentElement.scrollHeight || document.body.scrollHeight;
      //滚动条到底部的条件(距底部20px时触发加载)
      if (
        scrollTop + windowHeight >= scrollHeight - 450 &&
        !this.isAchiveBottom &&
        !this.noMore
      ) {
        this.isAchiveBottom = true;
        //延时触发数据加载
        setTimeout(() => {
          //后端需要进行分页，然后前端从后端拿来实现滚动加载
          //这里利用数组push来模拟从后端拿到的数据
          // this.datas.push("test");
          if (this.$route.query.active == 1) {
						if(this.parmsyingxiao.pageNumber<=this.total){
							this.parmsyingxiao.pageNumber += 1;
							this.getCouponGoodsListApi();
						}
          }
          if (this.$route.query.active == 2) {
						if(this.formparms.pageNumber<=this.totals){
							this.formparms.pageNumber += 1;
							this.allListjifen();
						}
          }
        }, 500);
      }
    };
  },
  mounted() {
    this.activeindex = this.$route.query.active;
    if (this.activeindex == 2) {
			this.titles='奥普斯®积分商城'
      this.jifenListcarty();
      this.allListjifen();
    }
    if (this.activeindex == 1) {
			this.titles='奥普斯®促销商城'
      this.getCouponGoodsListApi();
    }
  },
  methods: {
    sherchs() {
      this.goodsList = [];
      this.formparms.pageNumber = 1;
      this.parmsyingxiao.pageNumber = 1;
      if (this.activeindex == 2) {
        this.allListjifen();
      }
      if (this.activeindex == 1) {
        this.getCouponGoodsListApi();
      }
    },
    // 获取营销接口
    getCouponGoodsListApi() {
      getCouponGoodsList(this.parmsyingxiao)
        .then((res) => {
          if (res.code == 200) {
            res.result.records.forEach((element) => {
              this.goodsList.push(element);
            });

            this.isAchiveBottom = false;
						this.total=res.result.pages
          }
        })
        .catch((res) => {
          console.log("网络开小差了，没有获取到图片验证码哦");
        });
    },
    // 积分商品分类
    jifenListcarty() {
      getGoodspointsGoodscategory(this.parms)
        .then((res) => {
          if (res.code == 200) {
            this.tabbers = res.result.records;
          }
        })
        .catch((res) => {
          console.log("网络开小差了，没有获取到图片验证码哦");
        });
    },
    // 积分商品
    allListjifen() {
      getGoodspointsGoods(this.formparms)
        .then((res) => {
          if (res.code == 200) {
            // this.goodsList = res.result.records;
            res.result.records.forEach((element) => {
              this.goodsList.push(element);
            });
            this.isAchiveBottom = false;
						this.totals=res.result.pages
          }
        })
        .catch((res) => {
          console.log("网络开小差了，没有获取到图片验证码哦");
        });
    },
    getScroll($event) {
      console.log($event.target.scrollLeft);
    },
    // 全部积分商品
    allgoodsins() {
      this.goodsList = [];
      this.formparms.pointsGoodsCategoryId = "";
      this.formparms.pageNumber = 1;
      this.allListjifen();
    },
    toactivecard(index, id) {
      // console.log(
      //   this.$refs.fenleilistn.offsetLeft,
      //   this.$refs.libox[index].offsetLeft,
      //   this.$refs.fenleilistn.clientWidth -
      //     (this.$refs.libox[index].offsetLeft -
      //       this.$refs.fenleilistn.offsetLeft),
      //   this.$refs.libox[index].clientWidth
      // );
      this.indexs = index;
      let left =
        this.$refs.fenleilistn.clientWidth -
        (this.$refs.libox[index].offsetLeft -
          this.$refs.fenleilistn.offsetLeft);
      let width = this.$refs.libox[index].clientWidth;
      if (left < width) {
        this.$refs.fenleilistn.scrollLeft =
          left + this.$refs.libox[index + 1].clientWidth / 2;
      }
      //   this.$refs.fenleilistn.scrollLeft  = 200;
      // console.log(this.$refs.fenleilistn.scrollLeft);
      this.formparms.pageNumber = 1;
      this.formparms.pointsGoodsCategoryId = id;
      this.goodsList = [];
      this.allListjifen();
    },
    enter(index) {
      this.active = index;
    },
    leave(index) {
      this.active = null;
    },
    gotos(index, id, goodsid) {
      this.$router.push({
        path: "./shopdet?id=" + id + "&type=" + index + "&goodsid=" + goodsid,
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.opusmall {
  width: 100%;
  height: 400px;
  background: url("../assets/img/compare-dentistandpatient.png") no-repeat;
  background-size: cover;
  -webkit-background-size: cover;
  -o-background-size: cover;
  background-position: center 0;
  //overflow: hidden;
  position: relative;
	margin-top: 80px;
	//top: -1px;
  // padding-top: 80px;
  .titles {
		padding-top: 90px;
    //margin-top: 112px;
    text-align: center;
    font-size: 40px;
    color: #ffffff;
  }
  .imputs {
    width: 766px;
    height: 68px;
    // background: #ffffff;
    border-radius: 1px;
    display: flex;
    align-items: center;
    margin: 0 auto;
    margin-top: 36px;
    z-index: 100;
    position: absolute;
    left: 0;
    right: 0;
    .inputs {
      width: 560px;
      height: 100%;
      padding: 26px 28px;
      font-size: 16px;
      color: #333333;
    }
    .vielsiut {
      width: 205px;
      height: 67px;
      text-align: center;
      line-height: 67px;
      background: #0075a9;
      border-radius: 1px;
      font-size: 24px;
      color: #f8f8f8;
      cursor: pointer;
    }
  }
}
.bgcenters {
  width: 100%;
  height: 100%;
  background: url("../assets/img/216.png") no-repeat;
  background-position: -33% -30%;
  .bgcenters_cen {
    width: 100%;
    height: 100%;
    background: url("../assets/img/227.png") no-repeat;
    background-position: 140% 100%;
    padding-bottom: 137px;
    .lsicents {
      display: flex;
      align-items: center;
      justify-content: center;
      margin-top: 64px;
      .left,
      .right {
        width: 602px;
        height: 244px;
        display: flex;
        justify-content: start;
        .leftimg {
          margin-left: 38px;
          margin-top: 38px;
          width: 86px;
          height: 86px;
          border-radius: 50%;
        }
        .left_text {
          width: 70%;
          margin-left: 23px;
          font-size: 16px;
          color: #ffffff;
          .titles {
            margin-top: 40px;
            font-size: 36px;
          }
          .texts {
            width: 100%;
            margin: 25px 0 24px;
          }
        }
      }
      .left {
        background: linear-gradient(161deg, #4ad1f6, #0f9ce9);
        margin-right: 47px;
      }
      .right {
        background: linear-gradient(135deg, #f5c152, #e49d13);
      }
    }
  }
}
.texttitle {
  margin-top: 74px;
  text-align: center;
  font-size: 40px;
  color: #333333;
}
.lsitadposcen {
  max-width: 1250px;
  margin: 0 auto;
  margin-top: 76px;
  display: flex;
  // justify-content: space-between;
  flex-wrap: wrap;
  .goodeslist {
    position: relative;
    width: 392px;
    margin-bottom: 37px;
    box-shadow: 0px 8px 39px 7px rgba(55, 55, 55, 0.04);
    margin-right: 24px;
    cursor: pointer;
    .mengbang {
      width: 392px;
      height: 222px;
      background: rgba(0, 0, 0, 0.5);
      z-index: 99;
      position: absolute;
      top: 0;
      right: 0;
      left: 0;
      margin: 0 auto;
    }
    // float: left;
    .goodesimg {
      width: 392px;
      height: 221px;
    }
    .goodsbotom {
      padding: 15px 20px;
      .titles {
        // height: 72px;
        font-size: 24px;
        color: #333333;
        word-break: break-all;
        text-overflow: ellipsis;
        overflow: hidden;
        display: -webkit-box;
        -webkit-line-clamp: 1;
        -webkit-box-orient: vertical;
      }
      .price {
        display: flex;
        justify-content: space-between;
        margin-top: 10px;
        .prices {
          color: #00a3e0;
          font-size: 28px;
          .rmbs {
            font-size: 20px;
          }
        }
        .price_btns {
          // width: 134px;
          // height: 40px;
          padding: 10px 25px;
          background: #0075aa;
          color: #ffffff;
          border-radius: 4px;
          font-size: 14px;
          text-align: center;
          // line-height: 40px;
        }
        .price_btnsTwo {
          background: #ffffff;
          border: 1px solid #00a3e0;
          color: #00a3e0;
        }
      }
    }
  }
}
.btnsad {
  width: 198px;
  height: 60px;
  text-align: center;
  line-height: 60px;
  margin: 0 auto;
  margin-top: 117px;

  background: linear-gradient(90deg, #15a9e2, #15a9e2);
  box-shadow: 0px 10px 40px 0px rgba(26, 182, 240, 0.3);
  border-radius: 4px;
  font-size: 16px;
  color: #ffffff;
}
.fenleilistn {
  max-width: 1250px;
  margin: 0 auto;
  margin-top: 76px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  border-bottom: 1px solid #00a3e0;
  .allgoods {
    width: 243px;
    height: 56px;
    text-align: center;
    line-height: 56px;
    font-size: 24px;
    color: #ffffff;
    background: #00a3e0;
    cursor: pointer;
  }
  .goodsli {
    width: 1007px;
    display: flex;
    align-items: center;
    overflow-y: hidden;
    overflow-x: scroll;
    padding-left: 18px;
    .lis {
      font-size: 20px;
      color: #535353;
      padding: 0 18px;
      white-space: nowrap;
      cursor: pointer;
    }
  }
  .goodsli ::-webkit-scrollbar {
    display: none;
  }
  .goodsli::-webkit-scrollbar {
    width: 0 !important;
  }

  .goodsli {
    -ms-overflow-style: none;
  }

  .goodsli {
    overflow: -moz-scrollbars-none;
  }
}
</style>